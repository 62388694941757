import { payers } from "../definitions";
import { invoiceInferCategoryAndSubcategory } from "./inferCategoryAndSubcategory";

export const convertAiReceiptToExpense = (aiReceipt) => {
  const expense = {
    image: aiReceipt.image,
    entityType: "aiReceipt",
  };

  const inferredCategories = invoiceInferCategoryAndSubcategory({
    shop_name: aiReceipt.title + " " + aiReceipt.shop,
  });
  Object.assign(expense, inferredCategories);

  expense.title = aiReceipt.title || aiReceipt.title_jp || "";
  expense.date = aiReceipt.date || "";
  expense.notes = `Shop: ${aiReceipt.shop || ""} - Address: ${
    aiReceipt.address || ""
  }`;
  expense.yen = aiReceipt.total_yen || "";
  expense.eur = aiReceipt.total_eur || ""; // You might want to add currency conversion here
  expense.payer = "";
  if (aiReceipt.payer) {
    const payer = payers.find((el) => aiReceipt.payer === el.id);
    if (payer && payer.id) {
      expense.payer = payer.id;
    }
  }
  expense.payment_method = "card"; // Assuming card payment, adjust if needed
  expense.receipt_id = aiReceipt.Id;
  expense.entityType = "aiReceipt";

  return expense;
};

export const convertAiReceiptsToExpenses = (aiReceipts) => {
  return aiReceipts.map(convertAiReceiptToExpense);
};
