import axios from "axios";
import { urlAiReceipts, headersApi } from "./apiConfig";

export const fetchAiReceipts = async () => {
  const response = await axios.get(
    `${urlAiReceipts}?where=(categorized,is,null)~or(categorized,eq,false)`,
    { headers: headersApi }
  );
  return response?.data?.list || [];
};

export const submitAiReceipt = async (receipt) => {
  await axios.post(urlAiReceipts, receipt, { headers: headersApi });
};

export const updateAiReceipt = async (id, updates) => {
  await axios.patch(`${urlAiReceipts}/${id}`, updates, { headers: headersApi });
};

export const deleteAiReceipt = async (id) => {
  await axios.delete(`${urlAiReceipts}/${id}`, { headers: headersApi });
};
