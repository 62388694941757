import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Select, MenuItem, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { PageContainer } from '../shared/PageContainer';
import Menu from '../../components/Menu';
import { fetchBillsByYear } from '../../utils/apis/bills';
import { FaChartBar } from 'react-icons/fa';
import { FormattedCurrency } from '../../components/FormattedCurrency';
import { getCategoryIcon } from './CategoryIcons';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ContentContainer = styled(Paper)(({ theme }) => ({
  padding: '2rem',
  marginTop: '2rem',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '10px',
  width: '95%',
  maxWidth: '1200px',
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2rem',
  '& svg': {
    fontSize: '2.5rem',
    marginRight: '1rem',
    color: '#4CAF50',
  },
}));

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const CategoryIcon = styled('div')(({ theme }) => ({
  fontSize: '2rem',
  marginBottom: theme.spacing(1),
}));

export const BillStatistics = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [billsData, setBillsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [categoryTotals, setCategoryTotals] = useState({});
  const [monthlyData, setMonthlyData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchBillsByYear(selectedYear);
        setBillsData(data);
        calculateStatistics(data);
      } catch (error) {
        console.error('Error fetching bill data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedYear]);

  const calculateStatistics = (bills) => {
    const totals = {};
    const monthly = Array(12).fill().map(() => ({}));

    bills.forEach(bill => {
      const startDate = new Date(bill.from);
      const endDate = new Date(bill.to);
      const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;

      for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
        const month = d.getMonth();
        const dailyAmount = bill.yen / totalDays;

        if (!monthly[month][bill.type]) {
          monthly[month][bill.type] = 0;
        }
        monthly[month][bill.type] += dailyAmount;

        if (!totals[bill.type]) {
          totals[bill.type] = { yen: 0, eur: 0 };
        }
        totals[bill.type].yen += dailyAmount;
        totals[bill.type].eur += bill.eur / totalDays;
      }
    });

    // Round the final numbers
    for (const category in totals) {
      totals[category].yen = Math.round(totals[category].yen);
      totals[category].eur = Math.round(totals[category].eur);
    }

    monthly.forEach(month => {
      for (const category in month) {
        month[category] = Math.round(month[category]);
      }
    });

    setCategoryTotals(totals);
    setMonthlyData(monthly);
  };

  const chartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: Object.keys(categoryTotals).map((category, index) => ({
      label: category,
      data: monthlyData.map(month => month[category] || 0),
      borderColor: `hsl(${index * 30}, 70%, 50%)`,
      backgroundColor: `hsla(${index * 30}, 70%, 50%, 0.5)`,
    })),
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Monthly Expenses by Category (JPY)',
      },
    },
  };

  if (loading) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Menu />
      <ContentContainer elevation={3}>
        <Header>
          <FaChartBar />
          <Typography variant="h4" component="h1">Bill Statistics</Typography>
        </Header>

        <Grid container spacing={2} mb={4}>
          <Grid item xs={12}>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              fullWidth
            >
              {Array.from({ length: 10 }, (_, i) => new Date().getFullYear() - i).map((year) => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>

        <Grid container spacing={3} mb={4}>
          {Object.entries(categoryTotals).map(([category, amounts]) => (
            <Grid item xs={12} sm={6} md={4} key={category}>
              <StatCard>
                <CategoryIcon>{getCategoryIcon(category)}</CategoryIcon>
                <Typography variant="h6">{category}</Typography>
                <Typography variant="body1">
                  <FormattedCurrency amount={amounts.yen} currency="JPY" />
                </Typography>
                <Typography variant="body2">
                  <FormattedCurrency amount={amounts.eur} currency="EUR" />
                </Typography>
              </StatCard>
            </Grid>
          ))}
        </Grid>

        <Line options={chartOptions} data={chartData} />
      </ContentContainer>
    </PageContainer>
  );
};