import axios from 'axios';
import { urlBills, headersApi } from './apiConfig';
export const fetchBills = async ()=>
{
    const response = await  axios.get(urlBills+"?where=(categorized,is,null)~or(categorized,eq,false)&limit=4000", { headers:headersApi });
    const list =    response?.data?.list || []
    return list;
}

export const fetchBillsByMonth = async (year, month) => {
  const startDate = `${year}-${month.toString().padStart(2, '0')}-01`;
  const endDate = new Date(year, month, 0).toISOString().split('T')[0];
  
  const query = `(from,le,${endDate})~and(to,ge,${startDate})`;
  
  try {
    const response = await axios.get(`${urlBills}?where=${encodeURIComponent(query)}&limit=4000`, { headers: headersApi });
    return response.data.list || [];
  } catch (error) {
    console.error('Error fetching bills by month:', error);
    throw error;
  }
};



export const fetchBillsByYear = async (year) => {
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;
    
    const query = `(from,le,${endDate})~and(to,ge,${startDate})`;
    
    try {
      const response = await axios.get(`${urlBills}?where=${encodeURIComponent(query)}&limit=4000`, { headers: headersApi });
      return response.data.list || [];
    } catch (error) {
      console.error('Error fetching bills by year:', error);
      throw error;
    }
  };