import { invoiceInferCategoryAndSubcategory } from "./inferCategoryAndSubcategory";
import { payers } from "../definitions";

export const convertInvoiceToExpense = (invoice) => {
    const expense = {};
    const inferredCategories = invoiceInferCategoryAndSubcategory(invoice);
    Object.keys(inferredCategories).forEach((k) => {
      expense[k] = inferredCategories[k];
    });

    expense.title = invoice?.item_name || '';
    expense.date = invoice?.datetime_jp || '';
    if(expense.date && expense.date.includes(' '))
      expense.date = expense.date.split(' ')[0]
    expense.notes = invoice?.shop_name || ''  +  invoice?.Id;
    expense.yen = invoice?.yen || '';
    expense.eur = invoice?.eur || '';
    expense.payer = '';
    if(invoice?.payer){
      const payer = payers.find(el => invoice?.payer === el.name);
      if(payer && payer.id)
        expense.payer = payer.id
    }
    expense.payment_method = (invoice?.payment_id || '').includes('card') ? 'card' :'';
    expense.invoice_id   = invoice?.Id
    expense.entityType = "invoice";
    return expense;
  }