import { payers } from "../definitions";
import { billInferCategoryAndSubcategory } from "./inferCategoryAndSubcategory";

const buildNotes = (bill) => {
  if (bill?.type) {
    switch (bill?.type) {
      case "water":
        if (bill?.usage?.value && bill?.usage?.unit)
          return (
            "Acqua Utilizzata / 水道水使用量 " +
            bill?.usage?.value +
            " " +
            bill?.usage?.unit
          );
        break;

      default:
        break;
    }
  }

  return "";
};

export const convertBillsToExpenses = (bills) => {
  let expenses = [];
  bills.forEach((bill) => {
    expenses = expenses.concat(convertBillToExpenses(bill));
  });
  return expenses;
};

export const convertBillToExpenses = (bill) => {
  if (!bill?.from || !bill?.to) return [];

  const startDate = new Date(bill?.from);
  const endDate = new Date(bill?.to);
  const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1; // Including the last day
  const dailyExpenseYen = bill?.yen / totalDays;
  const dailyExpenseEur = bill?.eur / totalDays;

  let expenses = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const month = currentDate.getMonth();
    const year = currentDate.getFullYear();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const remainingDaysInMonth = daysInMonth - currentDate.getDate() + 1;

    const daysToAllocate = Math.min(
      remainingDaysInMonth,
      (endDate - currentDate) / (1000 * 60 * 60 * 24) + 1
    );
    const expenseAmountYen = dailyExpenseYen * daysToAllocate;
    const expenseAmountEur = dailyExpenseEur * daysToAllocate;

    const convertedBill = {
      title: bill?.title || "",
      date: `${year}-${String(month + 1).padStart(2, "0")}-01`,
      notes: buildNotes(bill),
      yen: expenseAmountYen.toFixed(0),
      eur: expenseAmountEur.toFixed(2),
      payer: "hiromi",
      payment_method: "card",
      entityType: "bill",
      invoice: bill?.invoice,
    };
    convertedBill.bill_id = bill?.Id;

    const inferredCategories = billInferCategoryAndSubcategory(bill);
    Object.keys(inferredCategories).forEach((k) => {
      convertedBill[k] = inferredCategories[k];
    });

    if (convertedBill.title.includes("Affitto")) {
      convertedBill.payer = "francesco";
      convertedBill.category = "housing-rent-loan";
    }

    expenses.push(convertedBill);

    currentDate.setDate(currentDate.getDate() + daysToAllocate);
  }

  expenses.forEach((expense) => {
    const payer = payers.find((el) => bill?.payer === el.name);
    if (payer && payer.id) {
      expense.payer = payer.id;
    }
  });

  return expenses;
};
