import axios from "axios";
import {
  urlBills,
  urlInvoices,
  urlExpense,
  headersApi,
  urlAiReceipts,
} from "./apiConfig";
export const submitExpense = async (expense) => {
  const type = expense?.entityType || "";
  delete expense["entityType"];

  await axios.post(urlExpense, expense, {
    headers: headersApi,
  });

  switch (type) {
    case "invoice":
      await axios.patch(
        urlInvoices + `/${expense.invoice_id}`,
        { categorized: true },
        {
          headers: headersApi,
        }
      );
      break;

    case "bill":
      await axios.patch(
        urlBills + `/${expense.bill_id}`,
        { categorized: true },
        {
          headers: headersApi,
        }
      );
      break;
    default:
      break;
    case "aiReceipt":
      await axios.patch(
        urlAiReceipts + `/${expense.receipt_id}`,
        { categorized: true },
        {
          headers: headersApi,
        }
      );
      break;
  }
};

export const fetchMonthlyExpenses = async (month) => {
  const startDate = `${month}-01`;
  const endDate = new Date(
    new Date(startDate).setMonth(new Date(startDate).getMonth() + 1) - 1
  )
    .toISOString()
    .split("T")[0];
  const url = `${urlExpense}?where=(date,gte,${startDate})~and(date,lte,${endDate})&limit=4000`;

  try {
    const response = await axios.get(url, { headers: headersApi });
    return response.data.list;
  } catch (error) {
    console.error("Error fetching monthly expenses:", error);
    throw error;
  }
};

export const fetchYearlyExpenses = async (year) => {
  const startDate = `${year}-01-01`;
  const endDate = `${year}-12-31`;
  const url = `${urlExpense}?where=(date,gte,${startDate})~and(date,lte,${endDate})&limit=4000`;

  try {
    const response = await axios.get(url, { headers: headersApi });
    const expenses = response.data.list;

    // Aggregate data by month
    const monthlyTotals = expenses.reduce((acc, expense) => {
      const month = expense.date.substring(0, 7);
      if (!acc[month]) {
        acc[month] = { totalYen: 0, totalEur: 0 };
      }
      acc[month].totalYen += parseFloat(expense.yen) || 0;
      acc[month].totalEur += parseFloat(expense.eur) || 0;
      return acc;
    }, {});

    return Object.entries(monthlyTotals).map(([month, totals]) => ({
      month,
      totalYen: totals.totalYen,
      totalEur: totals.totalEur,
    }));
  } catch (error) {
    console.error("Error fetching yearly expenses:", error);
    throw error;
  }
};
