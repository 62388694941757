import React, { useState } from 'react';
import { Typography, Paper, Grid, Select, MenuItem, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FaFileInvoice } from 'react-icons/fa';
import Menu from '../../components/Menu';
import { PageContainer } from '../shared/PageContainer';
import BillsByMonth from './BillsByMonth';

const ContentContainer = styled(Paper)(({ theme }) => ({
  padding: '2rem',
  marginTop: '2rem',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '0.5rem',
    paddingLeft: '0rem',
    paddingRight: '0rem', 
    paddingBottom: '0.5rem',
    width: '96%',
    marginLeft: '-3px',
  },
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '10px',
  width: '100%',
  maxWidth: '1200px',
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
}));

const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: '0.5rem',
    paddingRight: '0.5rem', 
  },
}));

const BillsByMonthContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    // width: '100vw',
    // marginLeft: '-5%',
    // marginRight: '-5%',
  },
}));

const SearchGrid = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '0.5rem',
        paddingRight: '0.5rem', 
      },
  }));

export const Bollette = () => {
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  const years = Array.from({length: 10}, (_, i) => new Date().getFullYear() - i);
  const months = Array.from({length: 12}, (_, i) => i + 1);

  return (
    <PageContainer>
      <Menu />
      <ContentContainer elevation={3}>
        <Header>
          <TitleContainer>
            <FaFileInvoice size={30} color="#4CAF50" />
            <Typography variant="h4" component="h1">Bills Overview</Typography>
          </TitleContainer>
        </Header>

        <SearchGrid container spacing={3} mb={4}>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>Select Year</Typography>
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                fullWidth
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>{year}</MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <Typography variant="subtitle1" gutterBottom>Select Month</Typography>
              <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                fullWidth
              >
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {new Date(2000, month - 1).toLocaleString('default', { month: 'long' })}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
        </SearchGrid>
        <BillsByMonthContainer>
            <BillsByMonth year={selectedYear} month={selectedMonth} />
        </BillsByMonthContainer>
       
      </ContentContainer>
    </PageContainer>
  );
};