import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Grid, TextField, Select, MenuItem, Button, Snackbar, IconButton, Card, CardContent, CardActions, Backdrop, CircularProgress, Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FaUniversity, FaWallet, FaChartLine, FaCreditCard, FaPaypal } from 'react-icons/fa';
import { MdEdit, MdSave, MdClose, MdCancel } from 'react-icons/md';
import { FaMale, FaFemale } from 'react-icons/fa';
import Menu from '../../components/Menu';
import { PageContainer } from '../shared/PageContainer';
import { accountBalanceApi } from '../../utils/apis/accounts';

const AccountsContainer = styled(PageContainer)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ContentContainer = styled(Paper)(({ theme }) => ({
  padding: '2rem',
  marginTop: '2rem',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '10px',
  width: '95%',
  maxWidth: '1920px',
  overflowX: 'auto',
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2rem',
  '& svg': {
    fontSize: '2.5rem',
    marginRight: '1rem',
    color: '#4CAF50',
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'all 0.3s',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[4],
  },
}));

const CardIconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  marginBottom: theme.spacing(2),
}));

const OwnerAvatar = styled(Avatar)(({ theme, owner }) => ({
  backgroundColor: owner === 'francesco' ? theme.palette.primary.main : theme.palette.secondary.main,
  marginRight: theme.spacing(1),
}));

const AccountIcon = ({ type }) => {
  switch (type) {
    case 'bank_account':
      return <FaUniversity size={24} />;
    case 'cash_wallet':
      return <FaWallet size={24} />;
    case 'securities':
      return <FaChartLine size={24} />;
    case 'payment_service':
      return <FaPaypal size={24} />;
    case 'prepaid_card':
      return <FaCreditCard size={24} />;
    default:
      return <FaUniversity size={24} />;
  }
};

export const BankAccountsForm = () => {
  const [accounts, setAccounts] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [snackbar, setSnackbar] = useState({ open: false, message: '' });

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const data = await accountBalanceApi.getAll();
      setAccounts(data);
    } catch (error) {
      console.error('Error fetching accounts:', error);
      setSnackbar({ open: true, message: 'Error fetching accounts' });
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await Promise.all(accounts.map(account => accountBalanceApi.update(account.Id, account)));
      setIsEditing(false);
      setSnackbar({ open: true, message: 'All accounts updated successfully' });
      await fetchAccounts();
    } catch (error) {
      console.error('Error updating accounts:', error);
      setSnackbar({ open: true, message: 'Error updating accounts' });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    fetchAccounts(); // Revert to original data
  };

  const handleChange = (id, field, value) => {
    setAccounts(accounts.map(account => 
      account.Id === id ? { ...account, [field]: value } : account
    ));
  };

  const getOwnerName = (owner) => {
    return owner === 'francesco' ? 'Francesco Sorice' : 'Hiromi Takeda';
  };

  return (
    <AccountsContainer>
      <Menu />
      <ContentContainer elevation={3}>
        <Header>
          <FaUniversity />
          <Typography variant="h4" component="h1">Bank Accounts</Typography>
        </Header>
        <Grid container spacing={3}>
          {accounts.map((account) => (
            <Grid item xs={12} sm={6} md={4} key={account.Id}>
              <StyledCard>
                <CardContent>
                  <CardIconWrapper>
                    <AccountIcon type={account.type} />
                  </CardIconWrapper>
                  <Typography variant="h6" component="h2" gutterBottom>
                    {account.name}
                  </Typography>
                  {isEditing ? (
                    <>
                      <TextField
                        fullWidth
                        label="Balance (EUR)"
                        value={account.balance_eur || ''}
                        onChange={(e) => handleChange(account.Id, 'balance_eur', e.target.value)}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Balance (YEN)"
                        value={account.balance_yen || ''}
                        onChange={(e) => handleChange(account.Id, 'balance_yen', e.target.value)}
                        margin="normal"
                      />
                      <Select
                        fullWidth
                        value={account.type}
                        onChange={(e) => handleChange(account.Id, 'type', e.target.value)}
                        margin="normal"
                      >
                        <MenuItem value="bank_account">Bank Account</MenuItem>
                        <MenuItem value="cash_wallet">Cash Wallet</MenuItem>
                        <MenuItem value="securities">Securities</MenuItem>
                        <MenuItem value="payment_service">Payment Service</MenuItem>
                        <MenuItem value="prepaid_card">Prepaid Card</MenuItem>
                      </Select>
                      <Select
                        fullWidth
                        value={account.owner}
                        onChange={(e) => handleChange(account.Id, 'owner', e.target.value)}
                        margin="normal"
                      >
                        <MenuItem value="francesco">Francesco Sorice</MenuItem>
                        <MenuItem value="hiromi">Hiromi Takeda</MenuItem>
                      </Select>
                      <Select
                        fullWidth
                        value={account.currency || ''}
                        onChange={(e) => handleChange(account.Id, 'currency', e.target.value)}
                        margin="normal"
                      >
                        <MenuItem value="EUR">EUR</MenuItem>
                        <MenuItem value="JPY">JPY</MenuItem>
                      </Select>
                    </>
                  ) : (
                    <>
                      <Typography variant="body1">
                        Balance: {account.balance_eur ? `€${account.balance_eur}` : `¥${account.balance_yen}`}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Type: {account.type}
                      </Typography>
                      
                      <Typography variant="body2" color="textSecondary">
                        Currency: {account.currency}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" style={{ display: 'flex', alignItems: 'center', marginTop: '1rem' }}>
                        <OwnerAvatar owner={account.owner} />
                        {getOwnerName(account.owner)}
                        
                      </Typography>
                    </>
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
        {isEditing ? (
          <>
            <Button
              variant="contained"
              color="primary"
              startIcon={<MdSave />}
              onClick={handleSave}
              style={{ marginTop: '2rem', marginRight: '1rem' }}
            >
              Save All
            </Button>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<MdCancel />}
              onClick={handleCancel}
              style={{ marginTop: '2rem' }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            startIcon={<MdEdit />}
            onClick={handleEdit}
            style={{ marginTop: '2rem' }}
          >
            Edit All
          </Button>
        )}
      </ContentContainer>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSnackbar({ ...snackbar, open: false })}
          >
            <MdClose />
          </IconButton>
        }
      />
      <Backdrop open={loading} style={{ zIndex: 10000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </AccountsContainer>
  );
};
