import React, { useState } from "react";
import { Dialog, IconButton, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FaTimes, FaChevronLeft, FaChevronRight } from "react-icons/fa";

const FullScreenDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: "100vw",
    height: "100vh",
    margin: 0,
    maxWidth: "none",
    maxHeight: "none",
  },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(3),
  top: theme.spacing(3),
  zIndex: 1,
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.common.white,
  padding: theme.spacing(1),
  border: "2px solid white",
  [theme.breakpoints.down("sm")]: {
    right: theme.spacing(1),
    top: theme.spacing(1),
    border: "1px solid white",
    fontSize: "1.2rem",
  },
  "&:hover": {
    backgroundColor: theme.palette.grey[900],
  },
  "& svg": {
    fontSize: "2rem",
  },
  boxShadow: theme.shadows[5],
  transition: "all 0.3s ease",
}));

const NavigationButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  color: theme.palette.common.white,
  backgroundColor: "rgba(0, 0, 0, 0.3)",
  border: "2px solid white",
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
}));

const ContentWrapper = styled(Box)({
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  backgroundColor: "rgba(0, 0, 0, 0.9)",
});

const FileViewer = ({ files, onClose, open = true }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : files.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < files.length - 1 ? prevIndex + 1 : 0
    );
  };

  const currentFile = files[currentIndex] || null;

  return (
    <FullScreenDialog open={open} onClose={onClose}>
      <ContentWrapper>
        {currentFile ? (
          currentFile.mimetype.startsWith("image/") ? (
            <img
              src={currentFile.url}
              alt={currentFile.title}
              style={{
                maxWidth: "100%",
                maxHeight: "100%",
                objectFit: "contain",
              }}
            />
          ) : (
            <iframe
              src={currentFile.url}
              title={currentFile.title}
              width="100%"
              height="100%"
              frameBorder="0"
            />
          )
        ) : (
          <>No File</>
        )}
        <CloseButton onClick={onClose} aria-label="close">
          <FaTimes />
        </CloseButton>
        {files.length > 1 && (
          <>
            <NavigationButton onClick={handlePrevious} style={{ left: 16 }}>
              <FaChevronLeft />
            </NavigationButton>
            <NavigationButton onClick={handleNext} style={{ right: 16 }}>
              <FaChevronRight />
            </NavigationButton>
          </>
        )}
      </ContentWrapper>
    </FullScreenDialog>
  );
};

export default FileViewer;
