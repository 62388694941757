import React, { useEffect, useState } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Select,
  MenuItem,
  Backdrop,
  CircularProgress,
  Typography,
  Paper,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
import {
  FaImage,
  FaFileInvoiceDollar,
  FaTimes,
  FaFileAlt,
} from "react-icons/fa";
import Menu from "../../components/Menu";
import { PageContainer } from "../shared/PageContainer";
import {
  fetchBills,
  fetchInvoices,
  convertInvoiceToExpense,
  convertBillsToExpenses,
  categories,
  subcategories,
  payers,
  submitExpense,
  fetchAiReceipts,
  convertAiReceiptsToExpenses,
} from "../../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FileViewer from "../bills/FileViewer";

const InvoicesContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled(Paper)`
  padding: 2rem;
  margin-top: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  width: 95%;
  overflow-x: auto;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  svg {
    font-size: 2.5rem;
    margin-right: 1rem;
    color: #4caf50;
  }
`;

const ResponsiveTable = styled(Table)`
  @media (max-width: 1440px) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      margin-bottom: 1rem;
      border: 1px solid #ccc;
    }

    td {
      border: none;
      position: relative;
      padding-left: 50%;

      &:before {
        content: attr(data-label);
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
      }
    }
  }
`;

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    background-color: #f5f5f5;
  }
`;

const DialogTitleStyled = styled(DialogTitle)`
  background-color: #4caf50;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ImagePreviewContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
`;
const ImagePreview = styled.img`
  max-width: 100%;
  min-height: 100vh;
`;

const CloseButton = styled(IconButton)`
  color: white;
`;

export const CategorizeInvoicesForm = () => {
  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imagePreview, setImagePreview] = useState(null);
  const [fileViewerOpen, setFileViewerOpen] = useState(false);
  const [currentFiles, setCurrentFiles] = useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleImagePreview = (imageUrl) => {
    setImagePreview(imageUrl);
  };

  const handleCloseImagePreview = () => {
    setImagePreview(null);
  };

  const handleFilePreview = (files) => {
    setCurrentFiles(files);
    setFileViewerOpen(true);
  };

  const handleCloseFileViewer = () => {
    setFileViewerOpen(false);
    setCurrentFiles([]);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [bills, invoices, aiReceipts] = await Promise.all([
          fetchBills(),
          fetchInvoices(),
          fetchAiReceipts(),
        ]);
        const convertedInvoices = invoices.map(convertInvoiceToExpense);
        const convertedBills = convertBillsToExpenses(bills);
        const convertedAiReceipts = convertAiReceiptsToExpenses(aiReceipts);
        setExpenses([
          ...convertedInvoices,
          ...convertedBills,
          ...convertedAiReceipts,
        ]);
      } catch (error) {
        console.error(`Failed to fetch data: ${error}`);
        toast.error("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleSend = async (index) => {
    const expense = expenses[index];
    setLoading(true);
    try {
      await submitExpense(expense);
      setExpenses(expenses.filter((_, i) => i !== index));
      toast.success("Expense submitted successfully!");
    } catch (error) {
      console.error(`Failed to send data: ${error}`);
      toast.error("Failed to submit expense. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e, index, field) => {
    const newExpenses = [...expenses];
    newExpenses[index][field] = e.target.value;
    setExpenses(newExpenses);
  };

  return (
    <InvoicesContainer maxWidth="2920px">
      <Menu />
      <ContentContainer elevation={3}>
        <Header>
          <FaFileInvoiceDollar />
          <Typography variant="h4" component="h1">
            Invoices
          </Typography>
        </Header>
        <ResponsiveTable>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Price (Yen)</TableCell>
              <TableCell>Price (Euro)</TableCell>
              <TableCell>Payer</TableCell>
              <TableCell>Payment Method</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {expenses.map((expense, index) => (
              <TableRow key={index}>
                <TableCell data-label="Title">
                  <TextField
                    label="Title"
                    multiline
                    minRows={2}
                    value={expense.title}
                    onChange={(e) => handleChange(e, index, "title")}
                    fullWidth
                  />
                </TableCell>
                <TableCell data-label="Category">
                  <Select
                    value={expense.category}
                    onChange={(e) => handleChange(e, index, "category")}
                    fullWidth
                  >
                    {categories.map((category) => (
                      <MenuItem key={category} value={category}>
                        {category}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell data-label="Subcategory">
                  <Select
                    value={expense.subcategory}
                    onChange={(e) => handleChange(e, index, "subcategory")}
                    fullWidth
                  >
                    {subcategories.map((subcategory) => (
                      <MenuItem key={subcategory} value={subcategory}>
                        {subcategory}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell data-label="Date">
                  <TextField
                    type="date"
                    value={expense.date}
                    onChange={(e) => handleChange(e, index, "date")}
                    fullWidth
                  />
                </TableCell>
                <TableCell data-label="Notes">
                  <TextField
                    label="Notes"
                    value={expense.notes}
                    onChange={(e) => handleChange(e, index, "notes")}
                    fullWidth
                  />
                </TableCell>
                <TableCell data-label="Price (Yen)">
                  <TextField
                    label="Price (Yen)"
                    value={expense.yen}
                    onChange={(e) => handleChange(e, index, "yen")}
                    fullWidth
                  />
                </TableCell>
                <TableCell data-label="Price (Euro)">
                  <TextField
                    label="Price (Euro)"
                    value={expense.eur}
                    onChange={(e) => handleChange(e, index, "eur")}
                    fullWidth
                  />
                </TableCell>
                <TableCell data-label="Payer">
                  <Select
                    value={expense.payer}
                    onChange={(e) => handleChange(e, index, "payer")}
                    fullWidth
                  >
                    {payers.map((payer) => (
                      <MenuItem key={payer.id} value={payer.id}>
                        {payer.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell data-label="Payment Method">
                  <Select
                    value={expense.payment_method}
                    onChange={(e) => handleChange(e, index, "payment_method")}
                    fullWidth
                  >
                    <MenuItem value="cash">Cash</MenuItem>
                    <MenuItem value="card">Card</MenuItem>
                    <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
                  </Select>
                </TableCell>
                <TableCell data-label="Action">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSend(index)}
                  >
                    Send
                  </Button>
                  {expense.invoice && (
                    <IconButton
                      onClick={() =>
                        handleFilePreview(JSON.parse(expense.invoice))
                      }
                      size="small"
                    >
                      <FaFileAlt />
                    </IconButton>
                  )}
                  {expense.image && (
                    <IconButton
                      onClick={() =>
                        handleImagePreview(JSON.parse(expense.image)[0].url)
                      }
                      size="small"
                    >
                      <FaImage />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </ResponsiveTable>
      </ContentContainer>
      <Backdrop open={loading} style={{ zIndex: 10000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <StyledDialog
        open={!!imagePreview}
        onClose={handleCloseImagePreview}
        maxWidth="md"
        fullWidth
        fullScreen={fullScreen}
      >
        <DialogTitleStyled>
          Receipt Image
          <CloseButton onClick={handleCloseImagePreview} size="large">
            <FaTimes />
          </CloseButton>
        </DialogTitleStyled>
        <DialogContent>
          <ImagePreviewContainer>
            <ImagePreview src={imagePreview} alt="Receipt" />
          </ImagePreviewContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImagePreview} color="primary">
            Close
          </Button>
        </DialogActions>
      </StyledDialog>
      <FileViewer
        open={fileViewerOpen}
        files={currentFiles}
        onClose={handleCloseFileViewer}
      />
      <ToastContainer position="bottom-right" autoClose={5000} />
    </InvoicesContainer>
  );
};
