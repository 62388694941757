import axios from 'axios';
import { headersApi, urlNetWorth } from './apiConfig';

export const netWorthApi = {
  // Create a new net worth history entry
  create: async (netWorthData) => {
    try {
      const response = await axios.post(urlNetWorth, netWorthData, { headers: headersApi });
      return response.data;
    } catch (error) {
      console.error('Error creating net worth history:', error);
      throw error;
    }
  },

  // Get all net worth history entries
  getAll: async () => {
    try {
      const response = await axios.get(urlNetWorth, { headers: headersApi });
      return response.data.list || [];
    } catch (error) {
      console.error('Error fetching net worth history:', error);
      throw error;
    }
  },

  // Get the latest net worth history entry
  getLatest: async () => {
    try {
      const response = await axios.get(`${urlNetWorth}?sort=-CreatedAt&limit=1`, { headers: headersApi });
      return response.data.list[0] || null;
    } catch (error) {
      console.error('Error fetching latest net worth history:', error);
      throw error;
    }
  },
};
