import React from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FaUser, FaYenSign, FaEuroSign, FaChartBar } from 'react-icons/fa';
import { FormattedCurrency } from '../../components/FormattedCurrency';

const DetailsContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
  },
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5, 0),
  },
}));

const DetailIcon = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.palette.text.secondary,
  width: '20px',
  display: 'flex',
  justifyContent: 'center',
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: 0,
}));

const getPayerName = (payer) => {
  return payer.toLowerCase() === 'hiromi' ? 'Hiromi Takeda' : 'Francesco Sorice';
};

const formatUsageUnit = (unit) => {
    if (unit === 'm3') {
      return 'm³';
    }
    return unit;
  };

const BillDetails = ({ bill }) => {
  const renderUsage = () => {
    if (!bill.usage) return null;
    const usage = JSON.parse(bill.usage);
    if (!usage.value || !usage.unit) return null;
    return (
      <>
        <StyledDivider />
        <DetailRow>
          <DetailIcon>
            <FaChartBar />
          </DetailIcon>
          <Typography variant="body2">
            Usage: {usage.value} {formatUsageUnit(usage.unit)}
            {usage.rate && usage.rate_unit && ` (${usage.rate} ${usage.rate_unit})`}
          </Typography>
        </DetailRow>
      </>
    );
  };

  return (
    <DetailsContainer>
      <Typography variant="subtitle1" gutterBottom>{bill.title}</Typography>
      <StyledDivider />
      <DetailRow>
        <DetailIcon>
          <FaUser />
        </DetailIcon>
        <Typography variant="body2">{getPayerName(bill.payer)}</Typography>
      </DetailRow>
      <StyledDivider />
      <DetailRow>
        <DetailIcon>
          <FaYenSign />
        </DetailIcon>
        <Typography variant="body2"><FormattedCurrency amount={bill.yen} currency="JPY" /></Typography>
      </DetailRow>
      <StyledDivider />
      <DetailRow>
        <DetailIcon>
          <FaEuroSign />
        </DetailIcon>
        <Typography variant="body2"><FormattedCurrency amount={bill.eur} currency="EUR" /></Typography>
      </DetailRow>
      {renderUsage()}
    </DetailsContainer>
  );
};

export default BillDetails;