export const categories = [
    'homelab-hosting-tech',
    'phone-net',
    'groceries-household',
    'dining-out-delivery',
    'transportation',
    'housing-rent-loan',
    'utilities-water-gas-light',
    'fun-parties-entrance-fees',
    'digital-fun-streaming-steam-games'
  ];

export const subcategories = [
    'konbini',
    'bento',
    'supermarket',
    'phone',
    'home-internet'
  ];

export const payers = [
    {"name":"Francesco Sorice", "id":"francesco"},
    {"name":"Hiromi Takeda", "id":"hiromi"},
]