import React, { useState, useEffect } from 'react';
import { Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchBillsByMonth } from '../../utils/apis/bills';
import BillCard from './BillCard';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
    margin: 0,
    width: 'unset',
  },
}));

const FullWidthGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: 0,
  },
}));

const GridItem = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    padding: 0,
    marginTop: theme.spacing(1), // Add small margin top for mobile
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: '1.75rem', // Slightly smaller font size for mobile
  },
}));

const BillsByMonth = ({ year, month }) => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const fetchedBills = await fetchBillsByMonth(year, month);
        setBills(fetchedBills);
      } catch (error) {
        console.error('Error fetching bills:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBills();
  }, [year, month]);

  if (loading) {
    return <CircularProgress />;
  }
  const sortedBills = [...bills].sort((a, b) => b.yen - a.yen);


  return (
    <StyledPaper elevation={3}>
      <TitleTypography variant="h4" gutterBottom>
        Bills for {new Date(year, month - 1).toLocaleString('default', { month: 'long' })} {year}
      </TitleTypography>
      <FullWidthGrid container spacing={{ xs: 0, sm: 3 }}>
        {sortedBills.map((bill) => (
          <GridItem item xs={12} sm={6} md={4} key={bill.Id}>
            <BillCard bill={bill} year={year} month={month} />
          </GridItem>
        ))}
      </FullWidthGrid>
    </StyledPaper>
  );
};

export default BillsByMonth;