import React, { useState, useEffect } from "react";
import { Paper, Typography, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FaExchangeAlt } from "react-icons/fa";
import { exchangeRateApi } from "../utils/apis/exchangeRate";

const ExchangeRateContainer = styled(Paper)(({ theme }) => ({
  position: "fixed",
  top: theme.spacing(2),
  //left: theme.spacing(2),
  left: "unset",
  right: "90px",
  padding: theme.spacing(1, 2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  borderRadius: "10px",
  boxShadow: theme.shadows[3],
  zIndex: 1000,

  [theme.breakpoints.down("sm")]: {
    display: "none",
  },

  [theme.breakpoints.down(1700)]: {
    left: "unset",
    right: "90px",
  },
}));

const RateRow = styled("div")({
  display: "flex",
  alignItems: "center",
  marginBottom: "4px",
});

const ExchangeIcon = styled(FaExchangeAlt)({
  marginLeft: "8px",
  marginRight: "8px",
  fontSize: "0.8rem",
});

export const ExchangeRate = () => {
  const [rates, setRates] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const latestRate = await exchangeRateApi.getLatestRate();
        setRates({
          eurToYen: latestRate.eurToYen.toFixed(2),
          yenToEur: latestRate.yenToEur.toFixed(5),
        });
      } catch (error) {
        console.error("Error fetching exchange rates:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchRates();
  }, []);

  if (loading) {
    return (
      <ExchangeRateContainer>
        <CircularProgress size={24} />
      </ExchangeRateContainer>
    );
  }

  return (
    <ExchangeRateContainer>
      <Typography variant="subtitle2" gutterBottom>
        Exchange Rate
      </Typography>
      <RateRow>
        <Typography variant="body2">1 EUR</Typography>
        <ExchangeIcon />
        <Typography variant="body2">{rates.eurToYen} JPY</Typography>
      </RateRow>
      <RateRow>
        <Typography variant="body2">1 JPY</Typography>
        <ExchangeIcon />
        <Typography variant="body2">{rates.yenToEur} EUR</Typography>
      </RateRow>
    </ExchangeRateContainer>
  );
};
