import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  FaHome,
  FaFileInvoiceDollar,
  FaUniversity,
  FaMoneyBillWave,
  FaChartLine,
  FaWallet,
  FaChartBar,
  FaFileInvoice,
  FaFileUpload,
} from "react-icons/fa";
import Menu from "../../components/Menu";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%);
`;

const Logo = styled.div`
  font-size: 3rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const CategoryContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 2rem;
`;

const CategoryTitle = styled.h2`
  color: #ffffff;
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  backdrop-filter: blur(10px);
  display: inline-block;
  transition: all 0.3s ease;

  &::after {
    content: "";
    display: block;
    width: 50%;
    height: 2px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0)
    );
    margin-top: 0.5rem;
  }
`;

const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

const StyledLink = styled(Link)`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 1rem;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 1);
  }

  svg {
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;

export const Home = () => {
  return (
    <PageContainer>
      <Menu />
      <Logo>
        <FaMoneyBillWave />
        ・🐨・🦍
      </Logo>

      <CategoryContainer>
        <CategoryTitle>Home</CategoryTitle>
        <LinkContainer>
          <StyledLink to="/">
            <FaHome />
            Home
          </StyledLink>
        </LinkContainer>
      </CategoryContainer>

      <CategoryContainer>
        <CategoryTitle>Expenses</CategoryTitle>
        <LinkContainer>
          <StyledLink to="/invoices">
            <FaFileInvoiceDollar />
            Invoices
          </StyledLink>
          <StyledLink to="/receipt-recognition">
            <FaFileUpload />
            Upload Receipt
          </StyledLink>
          <StyledLink to="/bills">
            <FaFileInvoice />
            Bills
          </StyledLink>
          <StyledLink to="/add-expense">
            <FaWallet />
            Add Expense
          </StyledLink>
        </LinkContainer>
      </CategoryContainer>

      <CategoryContainer>
        <CategoryTitle>Assets</CategoryTitle>
        <LinkContainer>
          <StyledLink to="/account">
            <FaUniversity />
            Bank Accounts
          </StyledLink>
          <StyledLink to="/net-worth">
            <FaChartLine />
            Net Worth
          </StyledLink>
        </LinkContainer>
      </CategoryContainer>

      <CategoryContainer>
        <CategoryTitle>Analytics</CategoryTitle>
        <LinkContainer>
          <StyledLink to="/monthly-stats">
            <FaChartBar />
            Monthly Stats
          </StyledLink>
          <StyledLink to="/bill-statistics">
            <FaChartBar />
            Bills Stats
          </StyledLink>
        </LinkContainer>
      </CategoryContainer>
    </PageContainer>
  );
};
