import React from 'react';
import { FaFire, FaBolt, FaTint, FaWifi, FaHome, FaDumbbell, FaQuestion } from 'react-icons/fa';

export const getCategoryIcon = (category) => {
  switch (category) {
    case 'gas':
      return <FaFire />;
    case 'electricity':
      return <FaBolt />;
    case 'water':
      return <FaTint />;
    case 'internet':
      return <FaWifi />;
    case 'rent':
      return <FaHome />;
    case 'gym':
      return <FaDumbbell />;
    default:
      return <FaQuestion />;
  }
};