import {CategorizeInvoicesForm} from './pages/invoices/CategorizeInvoicesForm';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {BankAccountsForm} from './pages/bank-accounts/BankAccounts';
import {Home} from './pages/home/Home';
import {AddExpense} from './pages/expenses/Expense';
import { NetWorth } from './pages/net-worth/NetWorth';
import { MonthlyStats } from './pages/expenses/MonthlyStats';
import { Bollette } from './pages/bills/Bills';
import { BillStatistics } from './pages/bills/BillsStatistics';
import { PWAPrompt } from './components/pwa/Prompt';
import { ReceiptRecognition } from './pages/invoices/ReceiptRecognition';


function App() {
  return (
    <Router>
      <PWAPrompt />
      <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/invoices" element={<CategorizeInvoicesForm />} />
          <Route path="/account" element={<BankAccountsForm />} />
          <Route path="/add-expense" element={<AddExpense />} />
          <Route path="/net-worth" element={<NetWorth />} />
          <Route path="/monthly-stats" element={<MonthlyStats />} />
          <Route path="/bills" element={<Bollette />} />
          <Route path="/bill-statistics" element={<BillStatistics />} />
          <Route path="/receipt-recognition" element={<ReceiptRecognition />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;