import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  Backdrop,
  LinearProgress,
  Box,
} from "@mui/material";
import styled from "styled-components";
import axios from "axios";
import Menu from "../../components/Menu";
import { PageContainer } from "../shared/PageContainer";
import { FaFileUpload, FaCamera } from "react-icons/fa";
import { receiptsApi } from "../../utils/apis/receipts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReceiptContainer = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentContainer = styled.div`
  padding: 2rem;
  margin-top: 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  width: 95%;
  max-width: 600px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  svg {
    font-size: 2.5rem;
    margin-right: 1rem;
    color: #4caf50;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const FileInput = styled.input`
  display: none;
`;

const FileInputLabel = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }

  svg {
    margin-right: 10px;
  }
`;

const ImagePreviewContainer = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  border-radius: 5px;
  overflow: hidden;
  background-color: #f0f0f0;
`;

const ImagePreview = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const ReceiptRecognition = () => {
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [hint, setHint] = useState("");
  const [submitter, setSubmitter] = useState("Hiromi");
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [receiptId, setReceiptId] = useState(null);

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const IMAGE_SIZE = 900;
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          canvas.width = IMAGE_SIZE;
          canvas.height = IMAGE_SIZE;

          // Calculate scaling factor to fit the image within IMAGE_SIZExIMAGE_SIZE
          const scale = Math.min(
            IMAGE_SIZE / img.width,
            IMAGE_SIZE / img.height
          );
          const width = img.width * scale;
          const height = img.height * scale;

          // Center the image on the canvas
          const x = (IMAGE_SIZE - width) / 2;
          const y = (IMAGE_SIZE - height) / 2;

          ctx.fillStyle = "white";
          ctx.fillRect(0, 0, IMAGE_SIZE, IMAGE_SIZE);
          ctx.drawImage(img, x, y, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, { type: "image/jpeg" }));
            },
            "image/jpeg",
            0.6
          ); // Set JPEG quality to 60%
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      const resizedFile = await resizeImage(selectedFile);
      setFile(resizedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(resizedFile);
    } else {
      setFile(null);
      setPreview(null);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) return;

    setLoading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append("image", file);
    formData.append(
      "options",
      JSON.stringify({
        type: "chatgpt",
        date_submitted: new Date().toISOString().split("T")[0],
        hint: hint,
        submitter: submitter,
      })
    );

    try {
      const response = await receiptsApi.submitReceipt(formData);
      console.log(response);
      setReceiptId(response.id);
      checkReceiptStatus(response.id);
    } catch (error) {
      console.error("Error uploading receipt:", error);
      toast.error(
        "An error occurred while uploading the receipt. Please try again."
      );
      setLoading(false);
    }
  };

  const checkReceiptStatus = async (id) => {
    console.log("Checking receipt status for", id);
    try {
      const elaborated = await receiptsApi.checkReceiptStatus(id);
      if (elaborated) {
        setLoading(false);
        toast.success("Receipt processed successfully!");
      } else {
        setProgress((prev) => Math.min(prev + 10, 90));
        setTimeout(() => checkReceiptStatus(id), 5000);
      }
    } catch (error) {
      console.error("Error checking receipt status:", error);
      setLoading(false);
      toast.error(
        "An error occurred while processing the receipt. Please try again."
      );
    }
  };

  return (
    <ReceiptContainer>
      <Menu />
      <ContentContainer>
        <Header>
          <FaFileUpload />
          <Typography variant="h4" component="h1">
            Receipt Recognition
          </Typography>
        </Header>
        <Form onSubmit={handleSubmit}>
          <FileInput
            type="file"
            id="receipt-upload"
            accept="image/*"
            capture="environment"
            onChange={handleFileChange}
          />
          <FileInputLabel htmlFor="receipt-upload">
            <FaCamera />
            {file ? file.name : "Choose a receipt image"}
          </FileInputLabel>
          {preview && (
            <ImagePreviewContainer>
              <ImagePreview src={preview} alt="Receipt preview" />
            </ImagePreviewContainer>
          )}
          <TextField
            label="Hint (optional)"
            value={hint}
            onChange={(e) => setHint(e.target.value)}
            fullWidth
          />
          <Select
            value={submitter}
            onChange={(e) => setSubmitter(e.target.value)}
            fullWidth
          >
            <MenuItem value="Hiromi">Hiromi</MenuItem>
            <MenuItem value="Francesco">Francesco</MenuItem>
          </Select>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!file || loading}
          >
            {loading ? "Processing..." : "Upload Receipt"}
          </Button>
        </Form>
      </ContentContainer>
      <Backdrop open={loading} style={{ zIndex: 10000 }}>
        <Box sx={{ width: "80%", maxWidth: 400 }}>
          <Typography variant="h6" align="center" gutterBottom>
            Processing Receipt
          </Typography>
          <LinearProgress variant="determinate" value={progress} />
          <Typography variant="body2" align="center" sx={{ mt: 1 }}>
            {progress}%
          </Typography>
        </Box>
      </Backdrop>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </ReceiptContainer>
  );
};
