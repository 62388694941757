import React, { useState, useEffect } from "react";
import {
  Typography,
  Paper,
  TextField,
  Select,
  MenuItem,
  Button,
  Grid,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { MdSave } from "react-icons/md";
import { FaMoneyBillWave } from "react-icons/fa";
import { PageContainer } from "../shared/PageContainer";
import Menu from "../../components/Menu";
import { submitExpense } from "../../utils/apis/expenses";
import { categories, subcategories, payers } from "../../utils/definitions";
import { exchangeRateApi } from "../../utils/apis/exchangeRate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContentContainer = styled(Paper)(({ theme }) => ({
  padding: "2rem",
  marginTop: "2rem",
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  borderRadius: "10px",
  width: "95%",
  maxWidth: "800px",
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: "2rem",
  "& svg": {
    fontSize: "2.5rem",
    marginRight: "1rem",
    color: "#4CAF50",
  },
}));

export const AddExpense = () => {
  const [expense, setExpense] = useState({
    Title: "",
    category: "",
    subcategory: "",
    date: new Date().toISOString().split("T")[0],
    notes: "",
    yen: "",
    eur: "",
    payer: "",
    payment_method: "",
  });
  const [loading, setLoading] = useState(true);

  const [exchangeRate, setExchangeRate] = useState(null);

  useEffect(() => {
    const fetchExchangeRate = async () => {
      setLoading(true);
      try {
        const { eurToYen } = await exchangeRateApi.getLatestRate();
        setExchangeRate(eurToYen);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
        toast.error("Error fetching exchange rate. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchExchangeRate();
  }, []);

  const handleChange = (field, value) => {
    setExpense({ ...expense, [field]: value });
  };
  const handleChangeMultiple = (fields) => {
    setExpense({ ...expense, ...fields });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await submitExpense(expense);
      // Reset form
      setExpense({
        Title: "",
        category: "",
        subcategory: "",
        date: new Date().toISOString().split("T")[0],
        notes: "",
        yen: "",
        eur: "",
        payer: "",
        payment_method: "",
      });
      toast.success("Expense added successfully!");
    } catch (error) {
      console.error("Error submitting expense:", error);
      toast.error("Error adding expense. Please try again.");
    }
  };

  return (
    <PageContainer>
      <Menu />
      <ContentContainer elevation={3}>
        <Header>
          <FaMoneyBillWave />
          <Typography variant="h4" component="h1">
            Aggiungi Spesa 支出記入
          </Typography>
        </Header>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Titolo タイトル"
                value={expense.Title}
                onChange={(e) => handleChange("Title", e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                value={expense.category}
                onChange={(e) => handleChange("category", e.target.value)}
                displayEmpty
                required
              >
                <MenuItem value="" disabled>
                  Categoria 範疇　支出の種類
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                value={expense.subcategory}
                onChange={(e) => handleChange("subcategory", e.target.value)}
                displayEmpty
              >
                <MenuItem value="">Subcategory</MenuItem>
                {subcategories.map((subcategory) => (
                  <MenuItem key={subcategory} value={subcategory}>
                    {subcategory}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                label="Date"
                value={expense.date}
                onChange={(e) => handleChange("date", e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Notes"
                value={expense.notes}
                onChange={(e) => handleChange("notes", e.target.value)}
                multiline
                rows={2}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Yen"
                onChange={(e) =>
                  handleChangeMultiple({
                    yen: e.target.value,
                    eur: "" + e.target.value / exchangeRate,
                  })
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Euro"
                value={expense.yen / exchangeRate}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                value={expense.payer}
                onChange={(e) => handleChange("payer", e.target.value)}
                displayEmpty
                required
              >
                <MenuItem value="" disabled>
                  Chi ha pagato? 支払者
                </MenuItem>
                {payers.map((payer) => (
                  <MenuItem key={payer.id} value={payer.id}>
                    {payer.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                value={expense.payment_method}
                onChange={(e) => handleChange("payment_method", e.target.value)}
                displayEmpty
                required
              >
                <MenuItem value="" disabled>
                  Tipologia di pagamento 支払い方法
                </MenuItem>
                <MenuItem value="cash">Cash</MenuItem>
                <MenuItem value="card">Card</MenuItem>
                <MenuItem value="bank_transfer">Bank Transfer</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<MdSave />}
                fullWidth
              >
                Save Expense
              </Button>
            </Grid>
          </Grid>
        </form>
      </ContentContainer>
      <Backdrop open={loading} style={{ zIndex: 10000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToastContainer position="bottom-right" autoClose={5000} />
    </PageContainer>
  );
};
