export const invoiceInferCategoryAndSubcategory = (invoice) => {
  let category = "groceries-household";
  let subcategory = "";

  const konbiniList = ["FAMILYMART", "SEVEN-ELEVEN"];
  const supermarketList = ["AEON GROUP", "DAISO", "SAMITSUTO"];
  const transportList = ["JR EAST MOBILE SUICA"];
  const restaurantList = [
    "TORIKIZOKU",
    "YOMENYA GOEMON",
    "MISTERDONUTIKEGAMISTATI",
    "CHIYODASUSHI IKEGAMITEN",
    "BANNAI NISHIKAMATA",
    "SAIZERIYA",
  ];
  const mobileOperatorList = ["MOBAL.COM"];
  const hostingServicesList = ["CONTABO", "ARUBA"];
  const funlist = ["CINEMA"];

  const shopName = (invoice?.shop_name || "").toLowerCase();

  if (
    transportList.some((transport) =>
      shopName.includes(transport.toLowerCase())
    )
  ) {
    category = "transportation";
  }

  if (konbiniList.some((konbini) => shopName.includes(konbini.toLowerCase()))) {
    subcategory = "konbini";
  }

  if (
    supermarketList.some((supermarket) =>
      shopName.includes(supermarket.toLowerCase())
    )
  ) {
    subcategory = "supermarket";
  }

  if (funlist.some((fun) => shopName.includes(fun.toLowerCase()))) {
    category = "fun-parties-entrance-fees";
  }

  if (
    restaurantList.some((restaurant) =>
      shopName.includes(restaurant.toLowerCase())
    )
  ) {
    category = "dining-out-delivery";
    subcategory = "";
  }

  if (
    mobileOperatorList.some((operator) =>
      shopName.includes(operator.toLowerCase())
    )
  ) {
    category = "phone-net";
    subcategory = "";
  }

  if (
    hostingServicesList.some((service) =>
      shopName.includes(service.toLowerCase())
    )
  ) {
    category = "homelab-hosting-tech";
    subcategory = "";
  }

  return {
    category,
    subcategory,
  };
};

export const billInferCategoryAndSubcategory = (bill) => {
  let category = "utilities-water-gas-light";
  let subcategory = "";

  const telecomList = ["FAMILYMART"];

  if (telecomList.includes(bill?.type || "")) {
    category = "phone-net";
  }

  return {
    category,
    subcategory,
  };
};
