import styled from "styled-components";
import React from "react";
import { ExchangeRate } from "../../components/ExchangeRate";

const Container = styled.div`
  min-height: 100vh;
  padding: 2rem;
  background: linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding: 0;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${(props) => props.maxWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PageContainer = ({ children, maxWidth = "1200px" }) => {
  return (
    <Container>
      <ContentWrapper className="content-wrapper" maxWidth={maxWidth}>
        <ExchangeRate />
        {children}
      </ContentWrapper>
    </Container>
  );
};
