import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: '16px',
    padding: theme.spacing(2),
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '20px',
  padding: theme.spacing(1, 3),
}));

export const PWAPrompt = () => {
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const [showNotificationPrompt, setShowNotificationPrompt] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallPrompt(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstall = () => {
    setShowInstallPrompt(false);
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
    setShowNotificationPrompt(true);
  };

  const handleNotificationConsent = () => {
    setShowNotificationPrompt(false);
    if ('Notification' in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          setShowSnackbar(true);
        }
      });
    }
  };

  return (
    <>
      <StyledDialog open={showInstallPrompt} onClose={() => setShowInstallPrompt(false)}>
        <DialogTitle>Install Our App</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Install our app for a better experience and quick access to your expenses!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setShowInstallPrompt(false)}>Not Now</StyledButton>
          <StyledButton onClick={handleInstall} color="primary" variant="contained">
            Install
          </StyledButton>
        </DialogActions>
      </StyledDialog>

      <StyledDialog open={showNotificationPrompt} onClose={() => setShowNotificationPrompt(false)}>
        <DialogTitle>Enable Notifications</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Would you like to receive notifications about your expenses and bill due dates?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setShowNotificationPrompt(false)}>No Thanks</StyledButton>
          <StyledButton onClick={handleNotificationConsent} color="primary" variant="contained">
            Enable
          </StyledButton>
        </DialogActions>
      </StyledDialog>

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowSnackbar(false)}
        message="Notifications enabled successfully!"
      />
    </>
  );
};