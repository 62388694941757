import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Collapse, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FaChevronDown, FaChevronUp, FaCheck, FaTimes, FaFire, FaBolt, FaTint, FaWifi, FaHome, FaDumbbell, FaFileAlt } from 'react-icons/fa';
import BillDetails from './BillDetails';
import FileViewer from './FileViewer';
import { FormattedCurrency } from '../../components/FormattedCurrency';


const StyledCard = styled(Card)(({ theme }) => ({
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
}));

const CardHeader = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(1, 2),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0.5),
  }
}));

const CategoryIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const CardActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, 1, 1),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 0.5, 0.25),
  }
}));

const ShowFilesButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  padding: theme.spacing(0.5, 1),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const getCategoryIcon = (type) => {
  switch (type) {
    case 'gas': return <FaFire />;
    case 'electricity': return <FaBolt />;
    case 'water': return <FaTint />;
    case 'internet': return <FaWifi />;
    case 'rent': return <FaHome />;
    case 'gym': return <FaDumbbell />;
    default: return null;
  }
};

const BillCard = ({ bill, year, month }) => {
  const [expanded, setExpanded] = useState(false);
  const [showFiles, setShowFiles] = useState(false);

  const calculatePartialAmount = (startDate, endDate, amount) => {
    const start = new Date(Math.max(new Date(startDate), new Date(year, month - 1, 1)));
    const end = new Date(Math.min(new Date(endDate), new Date(year, month, 0)));
    const totalDays = (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24) + 1;
    const partialDays = (end - start) / (1000 * 60 * 60 * 24) + 1;
    return (amount * partialDays / totalDays).toFixed(2);
  };

  const partialYen = calculatePartialAmount(bill.from, bill.to, bill.yen);
  const partialEur = calculatePartialAmount(bill.from, bill.to, bill.eur);

  const hasFiles = bill.invoice && JSON.parse(bill.invoice).length > 0;

  return (
    <StyledCard>
      <CardHeader>
        <CategoryIcon>
          {getCategoryIcon(bill.type)}
          <Typography variant="body1" style={{ marginLeft: '8px' }}>
            {bill.type.charAt(0).toUpperCase() + bill.type.slice(1)}
          </Typography>
        </CategoryIcon>
        <Typography variant="body2">
          {bill.from} - {bill.to}
        </Typography>
        <Typography variant="body2">
          Partial:  <FormattedCurrency amount={partialYen} currency="JPY" /> / <FormattedCurrency amount={partialEur} currency="EUR" />
        </Typography>
        {bill.categorized ? (
          <Typography variant="body2" color="success.main">
            <FaCheck /> Added to expenses
          </Typography>
        ) : (
          <Typography variant="body2" color="warning.main">
            <FaTimes /> Not added to expenses
          </Typography>
        )}
      </CardHeader>
      <CardActions>
        <IconButton onClick={() => setExpanded(!expanded)} size="small">
            {expanded ? <FaChevronUp /> : <FaChevronDown />}
        </IconButton>
        {hasFiles && (
            <ShowFilesButton
            onClick={() => setShowFiles(true)}
            startIcon={<FaFileAlt />}
            size="small"
            >
            Files
            </ShowFilesButton>
        )}
      </CardActions>
      <Collapse in={expanded}>
        <BillDetails bill={bill} />
      </Collapse>
      {showFiles && (
        <FileViewer
          files={JSON.parse(bill.invoice)}
          onClose={() => setShowFiles(false)}
        />
      )}
    </StyledCard>
  );
};

export default BillCard;