import axios from "axios";
import { headersApi, urlExchangeRate } from "./apiConfig";

const EXCHANGE_RATE_URL = urlExchangeRate;
const CACHE_KEY = "EXCHANGE_RATE_CACHE";
const CACHE_EXPIRY = 60 * 60 * 1000; // 1 hour in milliseconds

const getLatestRate = (rates) => {
  // Filter rates with base EUR
  const eurRates = rates.filter((rate) => rate.base === "EUR");

  if (eurRates.length > 0) {
    // If we have EUR base rates, find the latest one
    return eurRates.reduce((latest, current) => {
      return new Date(current.time_jp) > new Date(latest.time_jp)
        ? current
        : latest;
    });
  } else {
    // If no EUR base rates, find the latest rate and convert
    const latestRate = rates.reduce((latest, current) => {
      return new Date(current.time_jp) > new Date(latest.time_jp)
        ? current
        : latest;
    });
    return convertToEurBase(latestRate);
  }
};

const convertToEurBase = (rate) => {
  const eurValue = parseFloat(rate.euro_str);
  const yenValue = parseFloat(rate.yen_str);

  return {
    ...rate,
    base: "EUR",
    euro_str: "1",
    yen_str: (yenValue / eurValue).toFixed(5),
  };
};

const calculateRate = (rate) => {
  return {
    eurToYen: parseFloat(rate.yen_str),
    yenToEur: 1 / parseFloat(rate.yen_str),
    timestamp: rate.timestamp,
  };
};

const getCachedRate = () => {
  const cachedData = localStorage.getItem(CACHE_KEY);
  if (cachedData) {
    const { rate, timestamp } = JSON.parse(cachedData);
    if (Date.now() - timestamp < CACHE_EXPIRY) {
      return rate;
    }
  }
  return null;
};

const setCachedRate = (rate) => {
  localStorage.setItem(
    CACHE_KEY,
    JSON.stringify({
      rate,
      timestamp: Date.now(),
    })
  );
};

export const exchangeRateApi = {
  getLatestRate: async () => {
    const cachedRate = getCachedRate();
    if (cachedRate) {
      return cachedRate;
    }

    try {
      const response = await axios.get(EXCHANGE_RATE_URL, {
        headers: headersApi,
        params: {
          sort: "-time_jp",
          limit: 10,
        },
      });

      const latestRate = getLatestRate(response.data.list);
      const calculatedRate = calculateRate(latestRate);
      setCachedRate(calculatedRate);
      return calculatedRate;
    } catch (error) {
      console.error("Error fetching exchange rates:", error);
      throw error;
    }
  },
};
