import axios from "axios";
import { receiptsEndpoint } from "./apiConfig";
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2ZGQzM2YxMTRlNDJhMDA3NjIyNmQwZCIsInVzZXJuYW1lIjoidGVzdHVzZXIiLCJpYXQiOjE3MjU3NzI4MTF9.7enxIYt5mesmQoMCj9Z5nf5DLqVl1NYaduaySpW7mOc";
const HEADERS = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
};
const RECEIPTS_ENDPOINT = receiptsEndpoint;

export const receiptsApi = {
  submitReceipt: async (formData) => {
    try {
      const response = await axios.post(
        RECEIPTS_ENDPOINT + "/submit-receipt",
        formData,
        {
          headers: {
            ...HEADERS,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error uploading receipt:", error);
      throw error;
    }
  },

  checkReceiptStatus: async (id) => {
    try {
      const response = await axios.get(
        `${RECEIPTS_ENDPOINT}/check-receipt/${id}`,
        {
          headers: HEADERS,
        }
      );
      return response.data?.status;
    } catch (error) {
      console.error("Error checking receipt status:", error);
      throw error;
    }
  },
};
