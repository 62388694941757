export const urlInvoices = `https://noco.cipangu.it/api/v1/db/data/v1/Utili/Invoices`;
export const urlExpense = `https://noco.cipangu.it/api/v1/db/data/v1/Utili/Expenses`;
export const urlBills = `https://noco.cipangu.it/api/v1/db/data/v1/Utili/Bollette`;
export const urlAccounts = `https://noco.cipangu.it/api/v1/db/data/v1/Utili/AccountBalance`;
export const urlNetWorth = `https://noco.cipangu.it/api/v1/db/data/v1/Utili/BalanceHistory`;
export const urlExchangeRate = `https://noco.cipangu.it/api/v1/db/data/v1/Utili/ExchangeRate`;
export const urlAiReceipts = `https://noco.cipangu.it/api/v1/db/data/v1/Utili/AiReceipts`;
export const headersApi = {
  "xc-token": "zq838-BnIjNfsat5ilvhqGkBWoUw-lfNh9_uAB4E",
};
export const serverData = window.SERVER_DATA || {};
export const receiptsEndpoint =
  serverData.BACKEND_BASE_URL || "http://localhost:3500/api";

console.log("-----SETTINGS-----");
console.log(serverData);
console.log(receiptsEndpoint);
console.log(urlInvoices);
console.log(urlExpense);
console.log(urlBills);
console.log(urlAccounts);
console.log(urlNetWorth);
console.log(urlExchangeRate);
console.log(urlAiReceipts);
console.log(headersApi);
