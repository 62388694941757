import axios from 'axios';
import { headersApi,urlAccounts } from './apiConfig';
import { netWorthApi } from './netWorth';
import { exchangeRateApi } from './exchangeRate';
const BASE_URL = urlAccounts;

const calculateNetWorth = async (accounts) => {
    const { eurToYen } = await exchangeRateApi.getLatestRate();
    let totalEur = 0;
    let totalYen = 0;
  
    accounts.forEach(account => {
      if (account.balance_eur) {
        totalEur += parseFloat(account.balance_eur);
      }
      if (account.balance_yen) {
        totalYen += parseFloat(account.balance_yen);
      }
    });
  
    const netWorthEur = totalEur + (totalYen / eurToYen);
    const netWorthYen = (totalEur * eurToYen) + totalYen;
  
    return {
      amount_eur: totalEur,
      amount_yen: totalYen,
      net_worth_eur: netWorthEur,
      net_worth_yen: netWorthYen,
    };
  };

export const accountBalanceApi = {
  // Get all account balances
  updateAllAndSaveNetWorth: async (accounts) => {
    try {
      // Update all accounts
      await Promise.all(accounts.map(account => 
        axios.patch(`${BASE_URL}/${account.Id}`, account, { headers: headersApi })
      ));

      // Calculate net worth
      const netWorth = await calculateNetWorth(accounts);

      // Prepare net worth history data
      const netWorthData = {
        ...netWorth,
        month: new Date().toISOString().slice(0, 7), // YYYY-MM format
        person: 'francesco', // or get this dynamically
        balances: JSON.stringify(accounts),
      };

      // Save net worth history
      await netWorthApi.create(netWorthData);

      return { success: true, message: 'Accounts updated and net worth saved' };
    } catch (error) {
      console.error('Error updating accounts and saving net worth:', error);
      throw error;
    }
  },
  getAll: async () => {
    try {
      const response = await axios.get(BASE_URL, { headers: headersApi });
      return response.data.list || [];
    } catch (error) {
      console.error('Error fetching account balances:', error);
      throw error;
    }
  },

  // Create a new account balance
  create: async (accountBalance) => {
    try {
      const response = await axios.post(BASE_URL, accountBalance, { headers: headersApi });
      return response.data;
    } catch (error) {
      console.error('Error creating account balance:', error);
      throw error;
    }
  },

  // Get a single account balance by ID
  getById: async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/${id}`, { headers: headersApi });
      return response.data;
    } catch (error) {
      console.error(`Error fetching account balance with id ${id}:`, error);
      throw error;
    }
  },

  // Update an account balance
  update: async (id, updates) => {
    try {
      const response = await axios.patch(`${BASE_URL}/${id}`, updates, { headers: headersApi });
      return response.data;
    } catch (error) {
      console.error(`Error updating account balance with id ${id}:`, error);
      throw error;
    }
  },

  // Delete an account balance
  delete: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`, { headers: headersApi });
      return response.data;
    } catch (error) {
      console.error(`Error deleting account balance with id ${id}:`, error);
      throw error;
    }
  },

  // Get the count of account balances
  getCount: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/count`, { headers: headersApi });
      return response.data.count;
    } catch (error) {
      console.error('Error fetching account balance count:', error);
      throw error;
    }
  },

  // Find one account balance (useful for searching)
  findOne: async (params) => {
    try {
      const response = await axios.get(`${BASE_URL}/find-one`, { params, headers: headersApi });
      return response.data;
    } catch (error) {
      console.error('Error finding account balance:', error);
      throw error;
    }
  },

  // Bulk insert account balances
  bulkInsert: async (accountBalances) => {
    try {
      const response = await axios.post(`${BASE_URL}/bulk`, accountBalances, { headers: headersApi });
      return response.data;
    } catch (error) {
      console.error('Error bulk inserting account balances:', error);
      throw error;
    }
  },

  // Bulk update account balances
  bulkUpdate: async (updates) => {
    try {
      const response = await axios.patch(`${BASE_URL}/bulk`, updates, { headers: headersApi });
      return response.data;
    } catch (error) {
      console.error('Error bulk updating account balances:', error);
      throw error;
    }
  },

  // Bulk delete account balances by IDs
  bulkDelete: async (ids) => {
    try {
      const response = await axios.delete(`${BASE_URL}/bulk`, { data: ids, headers: headersApi });
      return response.data;
    } catch (error) {
      console.error('Error bulk deleting account balances:', error);
      throw error;
    }
  },

  // Export account balances
  export: async (type) => {
    try {
      const response = await axios.get(`${BASE_URL}/export/${type}`, { 
        responseType: 'blob',
        headers: headersApi
      });
      return response.data;
    } catch (error) {
      console.error(`Error exporting account balances as ${type}:`, error);
      throw error;
    }
  },
};
