import React, { useState, useEffect } from 'react';

import { Typography, Paper, Grid, CircularProgress, Select, MenuItem, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { PageContainer } from '../shared/PageContainer';
import Menu from '../../components/Menu';
import { fetchMonthlyExpenses, fetchYearlyExpenses } from '../../utils/apis/expenses';
import { LanguageSelector } from '../../components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { PieChart } from '../../components/charts/PieChart';
import { BarChart } from '../../components/charts/BarChart';
import { LineChart } from '../../components/charts/LineChart';
import { MonthChart } from '../../components/charts/MonthChart';
import { DataTable } from '../../components/DataTable';
import { downloadCSV, downloadExcel } from '../../utils/downloadHelpers';
import { FaUtensils, FaHome, FaLightbulb, FaChartBar, FaShoppingBasket } from 'react-icons/fa';


const ContentContainer = styled(Paper)(({ theme }) => ({
  padding: '2rem',
  marginTop: '2rem',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '10px',
  width: '95%',
  maxWidth: '1200px',
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '2rem',
}));

const ChartContainer = styled(Grid)(({ theme }) => ({
  marginBottom: '2rem',
}));
const TitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  }));


  const SummaryBox = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
  }));
  
  const TotalAmount = styled(Typography)(({ theme }) => ({
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  }));
  
  const CategoryBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
  }));
  
  const CategoryIcon = styled('div')(({ theme }) => ({
    fontSize: '1.5rem',
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  }));
  
  const AmountTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    '& .separator': {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      fontSize: '0.8em',
      verticalAlign: 'top',
      margin: '0 2px',
    },
  }));

  
const formatNumber = (number, currency) => {
    const parts = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).formatToParts(number);
  
    const formattedParts = parts.map(part => {
      if (part.type === 'group') {
        return <span key={part.value} className="separator">˙</span>;
      }
      return part.value;
    });
  
    return (
      <>
        {formattedParts}
        {' '}
        <span style={{ fontSize: '0.7em' }}>{currency}</span>
      </>
    );
  };
  

export const MonthlyStats = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date().toISOString().slice(0, 7));
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [focusColumn, setFocusColumn] = useState('category');
  const [vizTypes, setVizTypes] = useState(['pie']);

  // Generate an array of the last 10 years
  const years = Array.from({length: 10}, (_, i) => new Date().getFullYear() - i);

  // Generate an array of all months
  const months = [
    '01', '02', '03', '04', '05', '06',
    '07', '08', '09', '10', '11', '12'
  ];

  const calculateTotal = (data, currency) => {
    return data.reduce((acc, item) => acc + parseFloat(item[currency] || 0), 0).toFixed(2);
  };

  const calculateCategoryTotal = (data, category, currency) => {
    return data
      .filter(item => item.category === category)
      .reduce((acc, item) => acc + parseFloat(item[currency] || 0), 0)
      .toFixed(2);
  };


  useEffect(() => {
    fetchData();
  }, [selectedMonth, selectedYear]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const monthData = await fetchMonthlyExpenses(selectedMonth);
      const yearData = await fetchYearlyExpenses(selectedYear);
      setMonthlyData(monthData);
      setYearlyData(yearData);
    } catch (error) {
      console.error('Error fetching expense data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = (type) => {
    if (type === 'csv') {
      downloadCSV(monthlyData, 'expense_report.csv');
    } else {
      downloadExcel(monthlyData, 'expense_report.xlsx');
    }
  };

  return (
    <PageContainer>
      <Menu />
      <ContentContainer elevation={3}>
        <Header>
          <TitleContainer>
            <FaChartBar/>
            <Typography variant="h4" component="h1">{t('monthlyExpenseOverview')}</Typography>
          </TitleContainer>
        </Header>

        <Grid container spacing={3} mb={4}>
            <Grid item xs={12} sm={6} md={3}>
                <Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
                fullWidth
                >
                {months.map((month) => (
                    <MenuItem key={month} value={`${selectedYear}-${month}`}>
                    {new Date(`${selectedYear}-${month}-01`).toLocaleString('default', { month: 'long' })}
                    </MenuItem>
                ))}
                </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                fullWidth
                >
                {years.map((year) => (
                    <MenuItem key={year} value={year}>
                    {year}
                    </MenuItem>
                ))}
                </Select>
            </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Select
              value={focusColumn}
              onChange={(e) => setFocusColumn(e.target.value)}
              fullWidth
            >
              <MenuItem value="category">{t('category')}</MenuItem>
              <MenuItem value="subcategory">{t('subcategory')}</MenuItem>
              <MenuItem value="payer">{t('payer')}</MenuItem>
              <MenuItem value="payment_type">{t('paymentType')}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Select
              multiple
              value={vizTypes}
              onChange={(e) => setVizTypes(e.target.value)}
              fullWidth
            >
              <MenuItem value="pie">{t('pieChart')}</MenuItem>
              <MenuItem value="bar">{t('barChart')}</MenuItem>
              <MenuItem value="line">{t('lineChart')}</MenuItem>
              <MenuItem value="month">{t('monthChart')}</MenuItem>
            </Select>
          </Grid>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <ChartContainer container spacing={3}>
              {vizTypes.includes('pie') && (
                <Grid item xs={12} md={6}>
                  <PieChart data={monthlyData} focusColumn={focusColumn} />
                </Grid>
              )}
              {vizTypes.includes('bar') && (
                <Grid item xs={12} md={6}>
                  <BarChart data={monthlyData} focusColumn={focusColumn} />
                </Grid>
              )}
              {vizTypes.includes('line') && (
                <Grid item xs={12} md={6}>
                  <LineChart data={monthlyData} />
                </Grid>
              )}
              {vizTypes.includes('month') && (
                <Grid item xs={12} md={6}>
                  <MonthChart data={yearlyData} />
                </Grid>
              )}
            </ChartContainer>



            <SummaryBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <AmountTypography variant="h4">
                {formatNumber(calculateTotal(monthlyData, 'yen'), '¥')}
              </AmountTypography>
              <Typography variant="h6">{t('totalSpentYen')}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
            <AmountTypography variant="h4">
                {formatNumber(calculateTotal(monthlyData, 'eur'), '€')}
              </AmountTypography>
              <Typography variant="h6">{t('totalSpentEuro')}</Typography>
            </Grid>
          </Grid>
        </SummaryBox>

        <SummaryBox>
          <Typography variant="h5" gutterBottom>{t('categoryBreakdown')}</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <CategoryBox>
                <CategoryIcon><FaUtensils /></CategoryIcon>
                <Box>
                  <Typography variant="subtitle1">{t('diningOut')}</Typography>
                  <Typography>
                  {formatNumber(calculateCategoryTotal(monthlyData, 'dining-out-delivery', 'yen'), '¥')} / 
                  {formatNumber(calculateCategoryTotal(monthlyData, 'dining-out-delivery', 'eur'), '€')}
                  </Typography>
                </Box>
              </CategoryBox>
            </Grid>
            <Grid item xs={12} md={4}>
              <CategoryBox>
                <CategoryIcon><FaHome /></CategoryIcon>
                <Box>
                  <Typography variant="subtitle1">{t('housingRentLoan')}</Typography>
                  <Typography>
                  {formatNumber(calculateCategoryTotal(monthlyData, 'housing-rent-loan', 'yen'), '¥')} / 
                  {formatNumber(calculateCategoryTotal(monthlyData, 'housing-rent-loan', 'eur'), '€')}
                  </Typography>
                </Box>
              </CategoryBox>
            </Grid>
            <Grid item xs={12} md={4}>
                  <CategoryBox>
                    <CategoryIcon><FaLightbulb /></CategoryIcon>
                    <Box>
                      <Typography variant="subtitle1">{t('utilities')}</Typography>
                  <Typography>
                  {formatNumber(calculateCategoryTotal(monthlyData, 'utilities-water-gas-light', 'yen'), '¥')} / 
                  {formatNumber(calculateCategoryTotal(monthlyData, 'utilities-water-gas-light', 'eur'), '€')}
                  </Typography>
                </Box>
              </CategoryBox>
            </Grid>
            <Grid item xs={12} md={3}>
              <CategoryBox>
                <CategoryIcon><FaShoppingBasket /></CategoryIcon>
                <Box>
                  <Typography variant="subtitle1">{t('groceries')}</Typography>
                  <AmountTypography variant="body1">
                    {formatNumber(calculateCategoryTotal(monthlyData, 'groceries-household', 'yen'), '¥')} / 
                    {formatNumber(calculateCategoryTotal(monthlyData, 'groceries-household', 'eur'), '€')}
                  </AmountTypography>
                </Box>
              </CategoryBox>
            </Grid>
          </Grid>
        </SummaryBox>

            <DataTable data={monthlyData} />

            <Grid container spacing={2} mt={2}>
              <Grid item>
                <Button variant="contained" onClick={() => handleDownload('csv')}>
                  {t('downloadCSV')}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={() => handleDownload('excel')}>
                  {t('downloadExcel')}
                </Button>
              </Grid>
            </Grid>
          </>
        )}
      </ContentContainer>
    </PageContainer>
  );
};