import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  FaBars,
  FaTimes,
  FaMoneyBillWave,
  FaHome,
  FaFileInvoiceDollar,
  FaUniversity,
  FaWallet,
  FaChartLine,
  FaChartBar,
  FaFileInvoice,
  FaFileUpload,
} from "react-icons/fa";

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? "0" : "-150%")};
  width: 80vw;
  height: 100vh;
  background: linear-gradient(135deg, #84fab0 0%, #8fd3f4 100%);
  transition: left 0.3s ease-in-out;
  z-index: 1000;
  overflow-y: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    width: 300px;
  }
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
`;

const Logo = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
`;

const CategoryContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;

const CategoryTitle = styled.h2`
  color: #ffffff;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: inline-block;
  transition: all 0.3s ease;

  &::after {
    content: "";
    display: block;
    width: 50%;
    height: 2px;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0)
    );
    margin-top: 0.5rem;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const StyledLink = styled(Link)`
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  padding: 1rem;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 1);
  }

  svg {
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;

const BurgerIcon = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1001;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  font-size: 1.5rem;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;

const Menu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <>
      <BurgerIcon onClick={toggleMenu}>
        <FaBars />
      </BurgerIcon>
      <MenuContainer isOpen={isOpen}>
        <MenuHeader>
          <Logo>
            <FaMoneyBillWave />
            ・🐨・🦍
          </Logo>
          <CloseButton onClick={toggleMenu}>
            <FaTimes />
          </CloseButton>
        </MenuHeader>

        <CategoryContainer>
          <CategoryTitle>Home</CategoryTitle>
          <LinkContainer>
            <StyledLink to="/" onClick={toggleMenu}>
              <FaHome />
              Home
            </StyledLink>
          </LinkContainer>
        </CategoryContainer>

        <CategoryContainer>
          <CategoryTitle>Expenses</CategoryTitle>
          <LinkContainer>
            <StyledLink to="/invoices" onClick={toggleMenu}>
              <FaFileInvoiceDollar />
              Invoices
            </StyledLink>

            <StyledLink to="/receipt-recognition" onClick={toggleMenu}>
              <FaFileUpload />
              Upload Receipt
            </StyledLink>

            <StyledLink to="/bills" onClick={toggleMenu}>
              <FaFileInvoice />
              Bills
            </StyledLink>

            <StyledLink to="/add-expense" onClick={toggleMenu}>
              <FaWallet />
              Add Expense
            </StyledLink>
          </LinkContainer>
        </CategoryContainer>

        <CategoryContainer>
          <CategoryTitle>Assets</CategoryTitle>
          <LinkContainer>
            <StyledLink to="/account" onClick={toggleMenu}>
              <FaUniversity />
              Bank Accounts
            </StyledLink>
            <StyledLink to="/net-worth" onClick={toggleMenu}>
              <FaChartLine />
              Net Worth
            </StyledLink>
          </LinkContainer>
        </CategoryContainer>

        <CategoryContainer>
          <CategoryTitle>Analytics</CategoryTitle>
          <LinkContainer>
            <StyledLink to="/monthly-stats" onClick={toggleMenu}>
              <FaChartBar />
              Monthly Stats
            </StyledLink>

            <StyledLink to="/bill-statistics" onClick={toggleMenu}>
              <FaChartBar />
              Bills Stats
            </StyledLink>
          </LinkContainer>
        </CategoryContainer>
      </MenuContainer>
    </>
  );
};

export default Menu;
