import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { PageContainer } from '../shared/PageContainer';
import Menu from '../../components/Menu';
import { netWorthApi } from '../../utils/apis/netWorth';
import { exchangeRateApi } from '../../utils/apis/exchangeRate';
import { FaChartLine } from 'react-icons/fa';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const ContentContainer = styled(Paper)(({ theme }) => ({
  padding: '2rem',
  marginTop: '2rem',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  borderRadius: '10px',
  width: '95%',
  maxWidth: '1200px',
}));

const Header = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '2rem',
  '& svg': {
    fontSize: '2.5rem',
    marginRight: '1rem',
    color: '#4CAF50',
  },
}));

const StatCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const AmountTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    '& .separator': {
      color: theme.palette.primary.main,
      fontWeight: 'normal',
      fontSize: '0.8em',
      verticalAlign: 'top',
      margin: '0 2px',
    },
  }));
  
  const formatNumber = (number, currency) => {
    const parts = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).formatToParts(number);
  
    const formattedParts = parts.map(part => {
      if (part.type === 'group') {
        return <span key={part.value} className="separator">˙</span>;
      }
      return part.value;
    });
  
    return (
      <>
        {formattedParts}
        {' '}
        <span style={{ fontSize: '0.7em', verticalAlign: 'top' }}>{currency}</span>
      </>
    );
  };

export const NetWorth = () => {
  const [netWorthData, setNetWorthData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await netWorthApi.getAll();
        const latestRate = await exchangeRateApi.getLatestRate();
        setNetWorthData(data);
        
        const latest = data[data.length - 1];
        setCurrentStatus({
          netWorthYen: latest.net_worth_yen,
          netWorthEur: latest.net_worth_eur,
          currentEur: latest.amount_eur,
          currentYen: latest.amount_yen,
          exchangeRate: latestRate.eurToYen,
        });
      } catch (error) {
        console.error('Error fetching net worth data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Net Worth Over Time',
      },
    },
  };

  const chartData = {
    labels: netWorthData.map(d => d.month),
    datasets: [
      {
        label: 'Net Worth (EUR)',
        data: netWorthData.map(d => d.net_worth_eur),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Net Worth (YEN)',
        data: netWorthData.map(d => d.net_worth_yen),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  if (loading) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Menu />
      <ContentContainer elevation={3}>
        <Header>
          <FaChartLine />
          <Typography variant="h4" component="h1">Net Worth Overview</Typography>
        </Header>

        <Grid container spacing={3} mb={4}>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard>
              <Typography variant="h6">Current Net Worth (YEN)</Typography>
              <AmountTypography variant="h4">
                {formatNumber(currentStatus.netWorthYen, '¥')}
              </AmountTypography>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard>
              <Typography variant="h6">Current Net Worth (EUR)</Typography>
              <AmountTypography variant="h4">
                {formatNumber(currentStatus.netWorthEur, '€')}
              </AmountTypography>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard>
              <Typography variant="h6">Current EUR Held</Typography>
              <AmountTypography variant="h4">
                {formatNumber(currentStatus.currentEur, '€')}
              </AmountTypography>
            </StatCard>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <StatCard>
              <Typography variant="h6">Current YEN Held</Typography>
              <AmountTypography variant="h4">
                {formatNumber(currentStatus.currentYen, '¥')}
              </AmountTypography>
            </StatCard>
          </Grid>
        </Grid>

        <Line options={chartOptions} data={chartData} />
      </ContentContainer>
    </PageContainer>
  );
};
