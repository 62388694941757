import React from 'react';

const formatNumber = (number, currency) => {
    const parts = new Intl.NumberFormat('en-US', {
      style: 'decimal',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).formatToParts(number);
  
    const formattedParts = parts.map(part => {
      if (part.type === 'group') {
        return <span key={part.value} className="separator">˙</span>;
      }
      return part.value;
    });
  
    return (
      <>
        {formattedParts}
        {' '}
        <span style={{ fontSize: '0.7em' }}>{currency}</span>
      </>
    );
  };
  
  export const FormattedCurrency = ({ amount, currency }) => {
    const currencySymbol = currency === 'JPY' ? '¥' : '€';
    return <>{formatNumber(amount, currencySymbol)}</>;
  };