import React, { useState, useMemo } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, Collapse, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FaChevronUp, FaChevronDown } from 'react-icons/fa';
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileCardContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

const MobileCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const MobileCardHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px',
});

const MobileCardContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const MobileCardRow = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const DataTable = ({ data }) => {
  const [expandedRows, setExpandedRows] = useState({});

  const toggleRowExpansion = (index) => {
    setExpandedRows(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };
  const sortedData = useMemo(() => {
    return [...data].sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [data]);


  return (
    <>
      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Subcategory</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Payer</TableCell>
              <TableCell>Payment Type</TableCell>
              <TableCell>Yen</TableCell>
              <TableCell>EUR</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.category}</TableCell>
                <TableCell>{row.subcategory}</TableCell>
                <TableCell>{row.Title}</TableCell>
                <TableCell>{row.payer}</TableCell>
                <TableCell>{row.payment_type}</TableCell>
                <TableCell>¥{row.yen}</TableCell>
                <TableCell>€{row.eur}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <MobileCardContainer>
        {data.map((row, index) => (
          <MobileCard key={index} elevation={2}>
            <MobileCardHeader>
              <Typography variant="subtitle1">{row.date} {row.yen}¥</Typography>
              <IconButton size="small" onClick={() => toggleRowExpansion(index)}>
              {expandedRows[index] ? <FaChevronUp/> : <FaChevronDown/>}
              </IconButton>
            </MobileCardHeader>
            <Typography variant="h6">{row.Title}</Typography>
            <Collapse in={expandedRows[index]}>
              <MobileCardContent>
                <MobileCardRow>
                  <Typography variant="body2">Category:</Typography>
                  <Typography variant="body2">{row.category}</Typography>
                </MobileCardRow>
                <MobileCardRow>
                  <Typography variant="body2">Subcategory:</Typography>
                  <Typography variant="body2">{row.subcategory}</Typography>
                </MobileCardRow>
                <MobileCardRow>
                  <Typography variant="body2">Payer:</Typography>
                  <Typography variant="body2">{row.payer}</Typography>
                </MobileCardRow>
                <MobileCardRow>
                  <Typography variant="body2">Payment Type:</Typography>
                  <Typography variant="body2">{row.payment_type}</Typography>
                </MobileCardRow>
                <MobileCardRow>
                  <Typography variant="body2">Yen:</Typography>
                  <Typography variant="body2">¥{row.yen}</Typography>
                </MobileCardRow>
                <MobileCardRow>
                  <Typography variant="body2">EUR:</Typography>
                  <Typography variant="body2">€{row.eur}</Typography>
                </MobileCardRow>
              </MobileCardContent>
            </Collapse>
          </MobileCard>
        ))}
      </MobileCardContainer>
    </>
  );
};